import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function Slide() {
  return (
    <Layout>
      <Helmet title="Slide | The Goo Goo Dolls" />

      <h1>Slide</h1>

      <h2>The Goo Goo Dolls</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>Could you whisper in my ear</p>
          <p>The things you wanna feel?</p>
          <p>I'd give you anything</p>
          <p>To feel it comin'</p>
          <p>Do you wake up on your own</p>
          <p>And wonder where you are?</p>
          <p>You live with all your faults</p>
        </Verse>
        <PreChorus>
          <p>I wanna wake up where you are</p>
          <p>I won't say anything at all</p>
        </PreChorus>
        <Chorus>
          <p>So why don't you slide?</p>
          <p>Yeah, I'm gonna let it slide</p>
        </Chorus>
        <Verse>
          <p>Don't you love the life you killed?</p>
          <p>The priest is on the phone</p>
          <p>Your father hit the wall</p>
          <p>Your ma' disowned you</p>
          <p>Don't suppose I'll ever know</p>
          <p>What it means to be a man</p>
          <p>It's something I can't change</p>
          <p>I'll live around it</p>
        </Verse>
        <PreChorus>
          <p>I wanna wake up where you are</p>
          <p>I won't say anything at all</p>
        </PreChorus>
        <Chorus>
          <p>So why don't you slide?</p>
          <p>Ooh, slide</p>
        </Chorus>
        <Bridge>
          <p>And I'll do anything you ever dreamed to be complete</p>
          <p>Little pieces of the nothing that fall</p>
          <p>Oh, May</p>
          <p>Put your arms around me</p>
          <p>What you feel is what you are</p>
          <p>And what you are is beautiful</p>
          <p>Oh, May</p>
          <p>Do you wanna get married?</p>
          <p>Or run away?</p>
        </Bridge>
        <Bridge>
          <p>And I'll do anything you ever dreamed to be complete</p>
          <p>Little pieces of the nothing that fall</p>
          <p>Oh, May</p>
          <p>Put your arms around me</p>
          <p>What you feel is what you are</p>
          <p>And what you are is beautiful</p>
          <p>Oh, May</p>
          <p>Do you wanna get married?</p>
          <p>Or run away?</p>
        </Bridge>
        <PreChorus>
          <p>I wanna wake up where you are</p>
          <p>I won't say anything</p>
        </PreChorus>
        <Bridge>
          <p>And I'll do anything you ever dreamed to be complete (yeah, slide)</p>
          <p>Little pieces of the nothing that fall</p>
          <p>And I'll do anything you ever dreamed to be complete (yeah, slide)</p>
          <p>Little pieces of the nothing that fall (oh, oh slide)</p>
          <p>Yeah, slide between the sheets of all them beds you never knew (yeah, slide)</p>
          <p>Why don't you slide into my room?</p>
          <p>Just slide into my room</p>
          <p>Oh, we'll run away, run away, run away</p>
        </Bridge>
      </Columns>
    </Layout>
  );
}
